import React from "react";
import WideColumnSidebar from "../wide-column-sidebar/wide-column-sidebar";
import BlockSectionDescription from "../../components/blocks/block-section-description/block-section-description";
import { graphql } from "gatsby";
import {getImage} from "gatsby-plugin-image";
import ArticleContent from "../../components/article-content/article-content";
import ArticleHeader from "../../components/blocks/article-header/article-header";
import FooterTaxonomy from "../../components/blocks/footer/footer-taxonomy/footer-taxonomy";
import DetailArticleTagLink from "../../components/cards/article-details/detail-article-tag-link/detail-article-tag-link";



export const query = graphql `
query ($articleId: String!) {
  article: nodeArticle(id: {eq: $articleId}) {
    metatag {
      attributes {
        name
        content
        href
        property
        rel
      }
      tag
    }
    title
    field_pub_date_mises_org(formatString: "MM/DD/YYYY")
    body {
      value
    }
    relationships {
      field_author {
        title
        path {
          alias
        }
      }
      field_images {
        relationships {
          field_media_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 240
                  height: 165
                  transformOptions: {cropFocus: CENTER, fit: COVER}
                )
              }
            }
          }
        }
      }
      field_topics {
        id
        name
      }
      field_tags {
        id
        name
      }
      field_austrian_school {
        id
        name
      }
      field_article_type {
        name
        drupal_internal__tid
        langcode
      }
    }
    path {
      alias
    }
    langcode
  }
}
`;



export default function Article({ data }) {
    const image = getImage(data.article.relationships.field_images[0].relationships.field_media_image.localFile.childImageSharp.gatsbyImageData)

    const tagTaxonomies = [
        'field_tags',
        'field_topics',
        'field_austrian_school'
    ];
    let tagLinks = [];
    tagTaxonomies.forEach((fieldName) => {
        if (data.article.relationships[fieldName].length >= 1) {
            data.article.relationships[fieldName].forEach((tag, index) => {
                tagLinks.push(
                  <DetailArticleTagLink
                    key={tag.id}
                    id={tag.id}
                    name={tag.name}
                  />
                );
            });
        }
    });



    const article = <ArticleContent
        authorName={data.article.relationships.field_author[0].title}
        authorUrl={data.article.relationships.field_author[0].path.alias}
        date={data.article.field_pub_date_mises_org}
        tags={tagLinks}
        title={data.article.title}
        body={data.article.body.value}
        imageObj={image}
        metatag={data.article.metatag}
    ></ArticleContent>

    const header = <ArticleHeader iconType={data.article.relationships.field_article_type.drupal_internal__tid} sectionName={data.article.relationships.field_article_type.name}></ArticleHeader>

    const mainContent = [ header, article ];


    const sidebar = <BlockSectionDescription></BlockSectionDescription>

    const bottom = <FooterTaxonomy></FooterTaxonomy>

    return (
        <WideColumnSidebar wide={mainContent} sidebar={sidebar}  bottom={bottom}></WideColumnSidebar>
    )
}
