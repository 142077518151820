import ArticleDetails from "../cards/article-details/article-details";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";
import * as styles from "./article-content.module.scss";
import PropTypes from "prop-types";
import Metatags from "../metatags/metatags";

ArticleContent.propTypes = {
    authorName: PropTypes.string,
    authorUrl: PropTypes.string,
    date: PropTypes.string,
    title: PropTypes.string,
    tags: PropTypes.array,
    body: PropTypes.string,
    imageObj: PropTypes.object,
    metatag: PropTypes.array
};


export default function ArticleContent({title, authorName, authorUrl, date, articleType, tags, body, imageObj, metatag }) {
    return (
        <article>
            <Metatags metatagData={metatag}></Metatags>
            <h1 className={styles.title} dangerouslySetInnerHTML={{ __html:title}}></h1>
            <section className={styles.content}>
                <div className={styles.media}>
                    <div className={styles.image}>
                        <GatsbyImage image={imageObj} alt={title} />
                    </div>
                </div>
                <div className={styles.text}>
                    <div className={styles.tags}>Tags: {tags}</div>
                    <ArticleDetails
                        authorUrl={authorUrl}
                        date={date}
                        authorName={authorName}>
                    </ArticleDetails>
                    <div className={styles.body} dangerouslySetInnerHTML={{ __html:body}}></div>
                </div>
            </section>
        </article>
    )
}
